import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@web/styles/theme';
import { useTranslation } from 'react-i18next';

const Card = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 16px;

  font-family: ${theme.fonts.AccelerateChange};
  border: 1px solid ${theme.colors.black};
  border-radius: 8px;
  width: 100%;
`;

const ACLinkButton = styled.a`
  &&& {
    font-family: ${theme.fonts.AccelerateChange};
    background-color: transparent;
    border: none;
    outline: none;

    color: #2593f6;

    font-weight: 700;
    font-size: 16px;
    line-height: 150%;

    :hover {
      border: none;
      box-shadow: none;
      outline: none;
      color: #77baf7;
    }
  }
`;

const BolderText = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
`;

const RegularText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
`;

const AccelerateChangeCard = ({ name, address, age, selectLink }) => {
  const { t } = useTranslation();

  return (
    <Card>
      <div>
        <BolderText>{name}</BolderText>
        <RegularText>{address}</RegularText>
        <RegularText>
          {age} {t('check_registration.search_results.years_old')}
        </RegularText>
      </div>
      <ACLinkButton href={selectLink} rel="noopener noreferrer">
        {t('add_report.buttons.select')}
      </ACLinkButton>
    </Card>
  );
};

AccelerateChangeCard.propTypes = {
  address: PropTypes.string.isRequired,
  age: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  selectLink: PropTypes.string.isRequired,
};

export default AccelerateChangeCard;
