import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@web/styles/theme';

import { TwitterShareButton } from 'react-share';
import { TwitterOutlined } from '@ant-design/icons';
import Icon from '@web/components/common/Icon';
import { useTranslation } from 'react-i18next';

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: black;
`;

const BottomText = styled.div`
  font-family: ${theme.fonts.AccelerateChange};
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
`;

const Link = styled.a`
  font-family: ${theme.fonts.AccelerateChange};
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-decoration: none;
  color: black;

  display: flex;
  align-items: center;
  gap: 5px;

  :hover {
    color: #1c1c1c;
  }
`;

const Text = styled.p`
  margin: 0;
  padding: 0;
  font-family: ${theme.fonts.AccelerateChange};
  font-weight: 500;
  font-size: 16px;
  text-align: center;
`;

const Flex = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

const Centered = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-direction: column;
`;

const Container = styled.div`
  padding: 16px;
`;

const FooterWrapper = styled.div`
  margin-top: auto;
`;

const AccelerateChangeFooter = ({ useGetReadyToVote, didTheyVoteFlow }) => {
  const { t } = useTranslation();

  const shareUrl = useMemo(
    () => (didTheyVoteFlow ? `${window.location.origin}/my_vote_score` : 'didmyfriendvote.org'),
    [didTheyVoteFlow],
  );

  const shareTitle = useMemo(
    () => (didTheyVoteFlow ? 'Did they vote?' : 'Did my friend vote?'),
    [didTheyVoteFlow],
  );

  const checkAgainText = useMemo(
    () =>
      didTheyVoteFlow ? 'accelerate_change.check-again' : 'accelerate_change.check-another-friend',
    [didTheyVoteFlow],
  );

  const checkAgainLink = useMemo(
    () => (didTheyVoteFlow ? '/did_they_vote' : '/did_my_friend_vote'),
    [didTheyVoteFlow],
  );

  return (
    <FooterWrapper>
      {useGetReadyToVote && (
        <>
          <Divider />
          <Container>
            <Centered>
              <Text>{t('accelerate_change.make-sure-youre-ready')}</Text>
              <Link rel="noopener noreferrer" href="https://votehq.org/" target="_blank">
                {t('accelerate_change.get-ready-to-vote')} <Icon name="Open" />
              </Link>
              <Link href={checkAgainLink} rel="noopener noreferrer">
                {t(checkAgainText)} <Icon name="Refresh" />
              </Link>
            </Centered>
          </Container>
        </>
      )}
      <Divider />
      <Container>
        <Flex>
          <BottomText>{t('button.visit')}</BottomText>
          <TwitterShareButton url={shareUrl} title={shareTitle}>
            <TwitterOutlined />
          </TwitterShareButton>
        </Flex>
      </Container>
    </FooterWrapper>
  );
};

AccelerateChangeFooter.propTypes = {
  didTheyVoteFlow: PropTypes.bool,
  useGetReadyToVote: PropTypes.bool,
};

export default AccelerateChangeFooter;
