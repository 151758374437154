import React from 'react';
import i18n from '@web/components/i18n';

const { t } = i18n;

const getLastElectionYear = () => {
  const currentYear = new Date().getFullYear();
  return currentYear % 2 === 0 ? currentYear - 2 : currentYear - 1;
};

/**
 * Voterfile data comes in an Object, that has votes as "vb.vf_g2020" for general, and "vb.vf_p2020" for primary.
 * This function turns it into an array of objects, with a schema of:
 *  {
 *    year: 2020,
 *    type: "Primary",
 *    voted: true,
 *  }
 *  */
export const checkForElections = (voterFile, ignoreFilter = false) => {
  const lastElections = getLastElectionYear();
  //adding this to limit output to just three elections before current ones
  const threeElectionsBeforeNow = lastElections - 4;

  //we need to filter out elections in which people where not yet 18 Years old
  const firstElectionYear = voterFile['vb.voterbase_dob']
    ? parseInt(voterFile['vb.voterbase_dob'].slice(0, 4), 10) + 19
    : 0;

  const votedYears = Object.keys(voterFile).filter(
    key => key.startsWith('vb.vf_g2') || key.startsWith('vb.vf_p2'),
  );

  return Array.from({ length: lastElections - 2008 + 2 })
    .map((v, index) => {
      const isGeneral = index % 2 === 0;
      const year = lastElections - (isGeneral ? index : index - 1);
      return {
        type: isGeneral ? 'General' : 'Primary',
        voted: isGeneral
          ? votedYears.includes(`vb.vf_g${year}`)
          : votedYears.includes(`vb.vf_p${year}`),
        year,
      };
    })
    .filter(item =>
      ignoreFilter ? item : item.year >= firstElectionYear && item.year >= threeElectionsBeforeNow,
    )
    .sort((a, b) => {
      if (a.type > b.type) {
        return 1;
      }
      if (a.type < b.type) {
        return -1;
      }
      return 0;
    });
};

export const getLastElections = (electionsHistory, number = 3) => {
  const lastElectionYear = getLastElectionYear();

  const electionYears = [
    lastElectionYear,
    ...Array.from(Array(number - 1)).map((v, index) => lastElectionYear - 2 * (index + 1)),
  ];

  return electionYears.map(year => ({
    voted: electionsHistory.filter(entry => entry.year === year && entry.voted).length > 0,
    year,
  }));
};

export const getVoterIntroSentence = (lastElections, name, state, isRegistered) => {
  if (!isRegistered) {
    return (
      <>
        Public voting records indicate that <strong>{name}</strong> is not registered to vote in{' '}
        <strong>{state}</strong>.
      </>
    );
  }

  const votedYears = lastElections.filter(el => el.voted).map(y => y.year);
  const notVotedYears = lastElections.filter(el => !el.voted).map(y => y.year);

  if (isRegistered && votedYears.length < 1) {
    return (
      <>
        Public voting records indicate that <strong>{name}</strong> is registered to vote in{' '}
        <strong>{state}</strong>, and has not voted in any elections yet.
      </>
    );
  }

  const votedPart =
    votedYears.length > 1
      ? `${votedYears.slice(0, -1).join(', ')} and ${votedYears.slice(-1)}`
      : votedYears.join();
  const notVotedPart =
    notVotedYears.length > 1
      ? `${notVotedYears.slice(0, -1).join(', ')} or ${notVotedYears.slice(-1)}`
      : notVotedYears.join();

  return (
    <>
      {t('accelerate_change.intro.records-indicate')} <strong>{name}</strong>{' '}
      {t('accelerate_change.intro.registered-in')} <strong>{state},</strong>{' '}
      {t('accelerate_change.intro.has-voted')} {votedPart}
      {notVotedYears.length > 0 && (
        <>
          {t('accelerate_change.intro.but-not')} {notVotedPart}
        </>
      )}{' '}
      {t('accelerate_change.intro.elections')}.
    </>
  );
};

export const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
