import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ACRoutes from '@web/services/api/accelerateChange';
import theme, { media } from '@web/styles/theme';

import { message } from 'antd';
import Icon from '@web/components/common/Icon';
import AccelerateChangeFooter from '../AccelerateChangeFooter';

import AccelerateChangeHeader from '../AccelerateChangeHeader';
import AccelerateChangeButton from '../AccelerateChangeButton';
import ChooseVoterMenu from '../ChooseVoterMenu';
import FriendForm from '../FriendForm';
import useUtmParams from '../useUtmParams';

const PageWrapper = styled.div`
  color: black;

  width: 100%;
  min-height: 100vh;
  background-color: #fff2e1;

  display: flex;
  flex-direction: column;
`;

const InnerContainer = styled.div`
  width: 868px;
  margin: 24px auto 0;
  padding-bottom: 64px;

  ${media.nlg} {
    padding: 0 16px;
    width: 100%;
  }
`;

const Header = styled.h2`
  margin: 0;
  padding: 0;

  font-family: ${theme.fonts.AccelerateChange};
  font-weight: 700;
  font-size: 40px;
  line-height: 55px;
`;

const Text = styled.div`
  font-family: ${theme.fonts.AccelerateChange};
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
`;

const StyledUl = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`;

const ChooseVoter = ({ didTheyVoteFlow }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const params = useUtmParams();

  const [voterData, setVoterData] = useState(null);
  const [isError, setIsError] = useState(false);
  const [voterSearchInfo, setVoterSearchInfo] = useState(null);

  const onVoterDataSubmit = async (data, { setSubmitting }) => {
    const { address, first_name, last_name, age_range } = data;
    const { city, state } = address;
    ACRoutes.searchVoters(first_name, last_name, age_range, city, state)
      .then(data => {
        if (data?.data?.length > 1) {
          setVoterData(data.data);
          setVoterSearchInfo({ city, name: `${first_name} ${last_name}` });
        } else if (data?.data?.length === 1) {
          const path = didTheyVoteFlow ? 'did_they_vote' : 'did_my_friend_vote';
          history.push(`/${path}/${data.data[0].voterbase_id}${params.formattedParams}`);
        } else {
          setIsError(true);
        }
      })
      .catch(error => message.error(error?.response?.data?.message || error?.message))
      .finally(() => setSubmitting(false));
  };

  return (
    <PageWrapper>
      <AccelerateChangeHeader didTheyVoteFlow={didTheyVoteFlow} />
      <InnerContainer>
        {!voterData && !isError && (
          <FriendForm didTheyVoteFlow={didTheyVoteFlow} onSubmit={onVoterDataSubmit} />
        )}
        {voterData && !isError && (
          <ChooseVoterMenu
            didTheyVoteFlow={didTheyVoteFlow}
            voters={voterData}
            city={voterSearchInfo?.city}
            name={voterSearchInfo?.name}
          />
        )}
        {isError && (
          <>
            <Header>{t('accelerate_change.voter-not-found')}</Header>
            <Text>
              {t(
                `accelerate_change.${
                  didTheyVoteFlow ? 'we-couldnt-find-this-person' : 'we-couldnt-find-your-friend'
                }`,
              )}
            </Text>
            <Text>
              <StyledUl>
                <li>{t('accelerate_change.they-are-not-registered-to-vote')}</li>
                <li>{t('accelerate_change.their-name-is-spelled')}</li>
                <li>{t('accelerate_change.they-live-in-a-different-place')}</li>
                <li>{t('accelerate_change.records-are-not-yet-updated')}</li>
              </StyledUl>
              <AccelerateChangeButton onClick={() => setIsError(false)}>
                {t('common.try_again')} <Icon name="Refresh" />
              </AccelerateChangeButton>
            </Text>
          </>
        )}
      </InnerContainer>
      <AccelerateChangeFooter didTheyVoteFlow={didTheyVoteFlow} />
    </PageWrapper>
  );
};

ChooseVoter.propTypes = {
  didTheyVoteFlow: PropTypes.bool,
};

ChooseVoter.defaultProps = {
  didTheyVoteFlow: false,
};

export default ChooseVoter;
