import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { message } from 'antd';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SelectVoter from '../components/SelectPeople';
import CheckVoterScoreForm from '../components/CheckVoterScoreForm';

import { media } from '@web/styles/theme';
import ACRoutes from '@web/services/api/accelerateChange';
import useUtmParams from '@web/components/AccelerateChange/useUtmParams';

const PageWrapper = styled.div`
  color: black;

  width: 100%;
  min-height: 100vh;
  background-color: ${({ color }) => color ?? '#fff2e1'};

  display: flex;
  flex-direction: column;

  transition: background-color 0.3s ease-in-out;
`;

const InnerContainer = styled.div`
  width: 868px;
  margin: 24px auto 0;
  padding-bottom: 64px;

  ${media.nlg} {
    padding: 0 16px;
    width: 100%;
  }
`;

const SearchVoter = () => {
  const history = useHistory();
  const [voterData, setVoterData] = useState(null);
  const [voterSearchInfo, setVoterSearchInfo] = useState(null);

  const params = useUtmParams();

  const onVoterDataSubmit = async (data, { setSubmitting }) => {
    const { city, state_abbrev, first_name, last_name, dob } = data;

    let [month, day, year] = dob.split('/');

    ACRoutes.searchVoters(
      first_name,
      last_name,
      undefined,
      city,
      state_abbrev,
      `${year}-${month}-${day}`,
    )
      .then(data => {
        if (data?.data?.length > 1) {
          setVoterData(data.data);
          setVoterSearchInfo({ city, name: `${first_name} ${last_name}` });
        } else if (data?.data?.length === 1) {
          history.push(`/my_vote_score/${data.data[0].voterbase_id}${params.formattedParams}`);
        } else {
          message.info('Voters not found');
        }
      })
      .catch(error => message.error(error?.response?.data?.message || error?.message))
      .finally(() => setSubmitting(false));
  };

  return (
    <PageWrapper>
      <Header />
      <InnerContainer>
        {voterData && (
          <SelectVoter
            voters={voterData}
            city={voterSearchInfo?.city}
            name={voterSearchInfo?.name}
          />
        )}
        {!voterData && <CheckVoterScoreForm handleSubmit={onVoterDataSubmit} />}
      </InnerContainer>
      <Footer />
    </PageWrapper>
  );
};

export default SearchVoter;
