import React from 'react';

import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from '@web/styles/theme';
import * as yup from 'yup';

import { Formik } from 'formik';
import {
  AccelerateChangeAddress,
  AccelerateChangeInput,
  AccelerateChangeSelect,
  StyledErrorMessage,
} from './AccelerateChangeInput';
import AccelerateChangeButton from './AccelerateChangeButton';
import { useTranslation } from 'react-i18next';

const Label = styled.div`
  font-family: ${theme.fonts.AccelerateChange};
  font-weight: 700;
  font-size: 16px;
  line-height: 27px;
`;

const VertFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const HeaderFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Header = styled.h2`
  margin: 0;
  padding: 0;

  font-family: ${theme.fonts.AccelerateChange};
  font-weight: 700;
  font-size: 40px;
  line-height: 55px;
`;

const SubHeader = styled.h3`
  font-family: ${theme.fonts.AccelerateChange};
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
`;

const SubText = styled.div`
  color: #646464;
  font-family: ${theme.fonts.AccelerateChange};
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;

  margin: 0 auto;
  text-align: center;
`;

const FriendForm = ({ onSubmit, didTheyVoteFlow }) => {
  const { t } = useTranslation();

  const ageRanges = [
    {
      label: '18-25',
      value: '18-25',
    },
    {
      label: '25-30',
      value: '25-30',
    },
    {
      label: '30-40',
      value: '30-40',
    },
    {
      label: '40+',
      value: '40-150',
    },
  ];

  const validationSchema = yup.object().shape({
    address: yup
      .object()
      .nullable()
      .shape({
        city: yup.string().nullable().required('City is required!'),
        state: yup.string().nullable().required('State is required!'),
      }),
    age_range: yup.string().nullable().required('Age range is required!'),
    first_name: yup.string().nullable().required('First Name is required!'),
    last_name: yup.string().nullable().required('Last Name is required!'),
  });

  const initialValues = {
    address: '',
    age_range: null,
    first_name: '',
    last_name: '',
  };

  return (
    <VertFlex>
      <HeaderFlex>
        <Header>
          {t(`accelerate_change.${didTheyVoteFlow ? 'did-they-vote' : 'did-your-friend-vote'}`)}
        </Header>
        <SubHeader>
          {t(
            `accelerate_change.${
              didTheyVoteFlow ? 'whether-someone-voted' : 'whether-your-friend-voted'
            }`,
          )}
        </SubHeader>
      </HeaderFlex>
      <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSubmit}>
        {({
          isSubmitting,
          isValid,
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          setFieldValue,
          setFieldTouched,
        }) => (
          <form onSubmit={handleSubmit}>
            <VertFlex>
              <div>
                <Label>{t('idvoters.labels.firstname')}</Label>
                <AccelerateChangeInput
                  name="first_name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.first_name}
                />
                <StyledErrorMessage name="first_name" />
              </div>
              <div>
                <Label>{t('idvoters.labels.lastname')}</Label>
                <AccelerateChangeInput
                  name="last_name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.last_name}
                />
                <StyledErrorMessage name="last_name" />
              </div>
              <div>
                <Label>{t('add_report.form.age_range')}</Label>
                <AccelerateChangeSelect
                  name="age_range"
                  onChange={val => setFieldValue('age_range', val)}
                  onBlur={handleBlur}
                  value={values.age_range}
                  options={ageRanges}
                  placeholder="Select age range..."
                />
                <StyledErrorMessage name="age_range" />
              </div>
              <div>
                <Label>{t('accelerate_change.city-state')}</Label>
                <AccelerateChangeAddress
                  name="address"
                  placeholder=""
                  onChange={val => setFieldValue('address', val)}
                  onBlur={() => setFieldTouched('address')}
                />
                <StyledErrorMessage name="address.city" />
                <StyledErrorMessage name="address.state" />
              </div>
              <AccelerateChangeButton disabled={isSubmitting || !isValid} htmlType="submit">
                {t('accelerate_change.check-if-they-voted')}
              </AccelerateChangeButton>
            </VertFlex>
          </form>
        )}
      </Formik>

      <SubText>{t('accelerate_change.data-is-sourced')}</SubText>
    </VertFlex>
  );
};

FriendForm.propTypes = {
  didTheyVoteFlow: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

export default FriendForm;
