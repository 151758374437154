import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { TwitterShareButton } from 'react-share';
import { TwitterOutlined } from '@ant-design/icons';
import Icon from '@web/components/common/Icon';
import { useTranslation } from 'react-i18next';

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: black;
`;

const BottomText = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
`;

const Link = styled.a`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-decoration: none;
  color: black;

  display: flex;
  align-items: center;
  gap: 5px;

  :hover {
    color: #1c1c1c;
  }
`;

const Text = styled.p`
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
`;

const Flex = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

const Centered = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-direction: column;
`;

const Container = styled.div`
  padding: 16px;
`;

const FooterWrapper = styled.div`
  margin-top: auto;
`;

const Footer = ({ useGetReadyToVote }) => {
  const { t } = useTranslation();

  return (
    <FooterWrapper>
      {useGetReadyToVote && (
        <>
          <Divider />
          <Container>
            <Centered>
              <Text>Want to improve your vote score? Make sure you’re ready to vote.</Text>
              <Link rel="noopener noreferrer" href="https://votehq.org/" target="_blank">
                {t('accelerate_change.get-ready-to-vote')} <Icon name="Open" />
              </Link>
            </Centered>
          </Container>
        </>
      )}
      <Divider />
      <Container>
        <Flex>
          <BottomText>{t('button.visit')}</BottomText>
          <TwitterShareButton
            url={`${window.location.origin}/my_vote_score`}
            title="Check Your Vote Score"
          >
            <TwitterOutlined />
          </TwitterShareButton>
        </Flex>
      </Container>
    </FooterWrapper>
  );
};

Footer.propTypes = {
  useGetReadyToVote: PropTypes.bool,
};

export default Footer;
