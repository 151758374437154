import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import AccelerateChangeCard from './AccelerateChangeCard';
import theme from '@web/styles/theme';
import { capitalize } from './utils';
import { useTranslation } from 'react-i18next';
import useUtmParams from './useUtmParams';

const Text = styled.p`
  margin: 0;
  padding: 0;

  font-family: ${theme.fonts.AccelerateChange};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  > strong {
    font-weight: 900;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

//voters come from voterfile in this format:
// {
//   "voterbase_id": "2f69a900b76ba828253db0194af5252a33ae8ad3d3416b3cfacac1",
//   "vb.tsmart_first_name": "JACK",
//   "vb.tsmart_last_name": "SMITH",
//   "vb.tsmart_city": "TRUXTON",
//   "vb.tsmart_state": "NY",
//   "vb.tsmart_zip": "13158",
//   "vb.vf_party": "Democrat",
//   "vb.tsmart_cd": "019",
//   "vb.tsmart_sd": "052",
//   "vb.tsmart_hd": "131",
//   "vb.voterbase_dob": "20031217",
//   "vb.tsmart_full_address": "6130 DOG HOLLOW RD",
//   "vb.voterbase_age": "15-20"
// },

const ChooseVoterMenu = ({ voters, name, city, didTheyVoteFlow }) => {
  const { t } = useTranslation();
  const params = useUtmParams();

  return (
    <Flex>
      <Text
        dangerouslySetInnerHTML={{
          __html: t(
            didTheyVoteFlow
              ? 'accelerate_change.select-best-match'
              : 'accelerate_change.select-best-person',
            {
              city,
              name,
            },
          ),
        }}
      />
      {voters.map(voter => (
        <AccelerateChangeCard
          key={voter['voterbase_id']}
          address={`${capitalize(voter['vb.tsmart_city'])}, ${voter['vb.tsmart_state']}`}
          age={voter['vb.voterbase_age']}
          name={`${capitalize(voter['vb.tsmart_first_name'])} ${capitalize(
            voter['vb.tsmart_last_name'],
          )}`}
          selectLink={`/${didTheyVoteFlow ? 'did_they_vote' : 'did_my_friend_vote'}/${
            voter.voterbase_id
          }${params.formattedParams}`}
        />
      ))}
    </Flex>
  );
};

ChooseVoterMenu.propTypes = {
  city: PropTypes.string.isRequired,
  didTheyVoteFlow: PropTypes.bool,
  name: PropTypes.string.isRequired,
  voters: PropTypes.arrayOf(PropTypes.object),
};

export default ChooseVoterMenu;
