import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import theme, { media } from '@web/styles/theme';

import ACRoutes from '@web/services/api/accelerateChange';

import AccelerateChangeHeader from '../AccelerateChangeHeader';
import { message } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Spinner } from '@web/components/common/Loading';
import { capitalize, checkForElections, getLastElections, getVoterIntroSentence } from '../utils';
import AccelerateChangeButton from '../AccelerateChangeButton';
import AccelerateChangeFooter from '../AccelerateChangeFooter';
import Icon from '@web/components/common/Icon';
import { useTranslation } from 'react-i18next';
import useUtmParams from '../useUtmParams';

const PageWrapper = styled.div`
  color: black;

  width: 100%;
  min-height: 100vh;
  background-color: ${({ color }) => color ?? '#fff2e1'};

  display: flex;
  flex-direction: column;

  transition: background-color 0.3s ease-in-out;
`;

const InnerContainer = styled.div`
  width: 868px;
  margin: 24px auto 24px;

  ${media.nlg} {
    padding: 0 16px;
    width: 100%;
  }
`;

const Intro = styled.p`
  margin: 0;
  padding: 0;

  font-family: ${theme.fonts.AccelerateChange};
  font-weight: 500;
  font-size: 40px;
  line-height: 55px;
`;

const Header = styled.h2`
  font-family: ${theme.fonts.AccelerateChange};
  font-weight: 700;
  font-size: 24px;
  line-height: 55px;
`;

const Text = styled.p`
  margin: 0;
  padding: 0;

  font-family: ${theme.fonts.AccelerateChange};
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: black;
  margin: 25px 0 10px 0;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const VertFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 10px;
`;

const Share = styled(AccelerateChangeButton)`
  &&& {
    background-color: transparent;
    border: 1px solid black;
    color: black;

    margin-top: 10px;

    :hover {
      background-color: black;
      color: white;
    }
  }
`;

const getIcon = bool => (bool ? <CheckOutlined /> : <CloseOutlined />);

const VoterInfo = ({ didTheyVoteFlow }) => {
  const { voterbaseId } = useParams();
  const { t } = useTranslation();
  const params = useUtmParams();

  const [isLoading, setIsLoading] = useState(false);
  const [voterData, setVoterData] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    if (voterbaseId) {
      ACRoutes.getVoter(voterbaseId, params.utm_campaign, params.utm_source, params.utm_medium)
        .then(data => {
          if (Object.keys(data.data).length === 0) throw new Error('Voter not found!');
          setVoterData(data.data);
        })
        .catch(error => message.error(error?.response?.data?.message || error?.message))
        .finally(() => setIsLoading(false));
    }
  }, [params.utm_campaign, params.utm_medium, params.utm_source, voterbaseId]);

  const electionsHistory = useMemo(
    () => (voterData ? checkForElections(voterData) : null),
    [voterData],
  );

  const lastThreeElections = useMemo(
    () => (electionsHistory ? getLastElections(electionsHistory) : null),
    [electionsHistory],
  );

  const name = voterData
    ? `${capitalize(voterData?.['vb.tsmart_first_name'])} ${capitalize(
        voterData['vb.tsmart_last_name'],
      )}`
    : null;
  const address = voterData
    ? `${capitalize(voterData?.['vb.tsmart_city'])}, ${voterData?.['vb.tsmart_state']}`
    : null;

  const getBgColor = () => {
    if (!lastThreeElections) return null;
    return lastThreeElections.filter(el => el.voted).length > 0 ? '#b6ddfc' : '#ea5d58';
  };

  const handleShare = () => {
    const url = window.location.href;

    if (window.navigator.share) {
      navigator.share({
        name: 'Did My Friends Vote?',
        url,
      });
    } else {
      navigator?.clipboard
        .writeText(url)
        .then(() => message.success('Link copied!'))
        .catch(() => message.error('Error copying link'));
    }
  };

  const isRegistered = useMemo(
    () => voterData?.['vb.voterbase_registration_status'] === 'Registered',
    [voterData],
  );

  return (
    <PageWrapper color={getBgColor()}>
      <AccelerateChangeHeader useCurrentUrl didTheyVoteFlow={didTheyVoteFlow} />
      <InnerContainer>
        {voterData && (
          <>
            <Intro>{getVoterIntroSentence(lastThreeElections, name, address, isRegistered)}</Intro>
            <Divider />
            <Header>{t('accelerate_change.public-voting-record')}</Header>
            <Text>
              <strong>{name}</strong>
            </Text>
            <Text>{address}</Text>
            <VertFlex>
              {electionsHistory.map(election => (
                <Flex>
                  <Text>
                    {election.year} {election.type} election
                  </Text>{' '}
                  {getIcon(election.voted)}
                </Flex>
              ))}
            </VertFlex>

            <Share onClick={handleShare}>
              {t('button.share')} <Icon name="Upload" />
            </Share>
          </>
        )}
        {isLoading && <Spinner />}
        {!voterData && !isLoading && t('accelerate_change.id-was-not-found')}
      </InnerContainer>
      <AccelerateChangeFooter didTheyVoteFlow={didTheyVoteFlow} useGetReadyToVote />
    </PageWrapper>
  );
};

VoterInfo.propTypes = {
  didTheyVoteFlow: PropTypes.bool,
};

VoterInfo.defaultProps = {
  didTheyVoteFlow: false,
};

export default VoterInfo;
