import styled from 'styled-components';
import { Button } from 'antd';
import theme from '@web/styles/theme';

const StyledButton = styled(Button)`
  &&& {
    font-family: ${theme.fonts.AccelerateChange};
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    height: 46px;
    width: 100%;
    background-color: #b6dcfe;
    border: none;
    border-radius: 8px;

    display: flex;
    gap: 5px;

    :hover {
      background-color: #77c4ff;
      color: ${theme.colors.black};
      border: none;
    }

    :disabled {
      border: none;
      background-color: ${theme.colors.white};
      opacity: 35%;
      color: black;
    }
  }
`;

export default StyledButton;
