import React, { useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import { Button } from 'antd';
import { Form, Formik } from 'formik';
import FormInput from '@web/components/common/shared/FormInput';
import FormDatePicker from '@web/components/common/shared/FormDatePicker';
import AddressFormField from '@web/components/common/shared/AddressFormField';

import theme from '@web/styles/theme';

const VertFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const HeaderFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Header = styled.h2`
  margin: 0;
  padding: 0;

  font-weight: 700;
  font-size: 40px;
  line-height: 55px;
`;

const SubHeader = styled.h3`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
`;

const SubText = styled.div`
  color: #646464;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;

  margin: 0 auto;
  text-align: center;
`;

const StyledButton = styled(Button)`
  &&& {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    height: 46px;
    width: 100%;
    background-color: #b6dcfe;
    border: none;
    border-radius: 8px;

    display: flex;
    gap: 5px;

    :hover {
      background-color: #77c4ff;
      color: ${theme.colors.black};
      border: none;
    }

    :disabled {
      border: none;
      background-color: ${theme.colors.white};
      opacity: 35%;
      color: black;
    }
  }
`;

const CheckVoterScoreForm = ({ handleSubmit }) => {
  const setAddress = useCallback((address, setFieldValue) => {
    setFieldValue('address', address.address);
    setFieldValue('zip_code', address.zip_code);
    setFieldValue('city', address.city);
    setFieldValue('state_abbrev', address.state_abbrev);
  }, []);

  const validationSchema = yup.object().shape({
    address: yup
      .object()
      .nullable()
      .shape({
        city: yup.string().nullable().required('City is required!'),
        state: yup.string().nullable().required('State is required!'),
      }),
    dob: yup
      .string()
      .nullable()
      .matches(
        /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/(19|20)\d{2}$/,
        'Date must be in MM/DD/YYYY format',
      )
      .required('DOB is required!'),
    first_name: yup.string().nullable().required('First Name is required!'),
    last_name: yup.string().nullable().required('Last Name is required!'),
  });

  return (
    <VertFlex>
      <HeaderFlex>
        <Header>Check Your Vote Score</Header>
        <SubHeader>
          Who you voted for is always private, but whether you voted is public record. Check your
          record of voting below.
        </SubHeader>
      </HeaderFlex>
      <Formik
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        render={({ values, handleChange, setFieldValue, isSubmitting, isValid }) => {
          return (
            <Form style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
              <FormInput
                key="first_name"
                label="First Name"
                name="first_name"
                value={values.first_name}
                onChange={handleChange}
                fontColor="#000000"
                backgroundColor="#fff2e1"
                outlineColor="#000000"
                labelFontColor="#000000"
              />
              <FormInput
                key="last_name"
                label="Last Name"
                name="last_name"
                value={values.last_name}
                onChange={handleChange}
                fontColor="#000000"
                backgroundColor="#fff2e1"
                outlineColor="#000000"
                labelFontColor="#000000"
              />
              <FormDatePicker
                key="dob"
                hidePrefix
                label="Date of Birth"
                placeholder="MM / DD / YYYY"
                backgroundColor="#fff2e1"
                outlineColor="#000000"
                fontColor="#000000"
                name="dob"
                value={values.age_range}
                onChange={e => setFieldValue('dob', e.target.value)}
              />
              <AddressFormField
                placeholder=""
                hidePrefix
                label="Address"
                name="address"
                key={'address'}
                onChange={data => setAddress(data, setFieldValue)}
                fontColor="#000000"
                backgroundColor="#fff2e1"
                outlineColor="#000000"
              />
              <StyledButton disabled={isSubmitting || !isValid} htmlType="submit">
                Check Your Vote Score
              </StyledButton>
              <SubText>
                Data is sourced from publicly available voter registration data provided by the
                state.
              </SubText>
            </Form>
          );
        }}
      />
    </VertFlex>
  );
};

CheckVoterScoreForm.propTypes = {
  handleSubmit: PropTypes.func,
};

export default CheckVoterScoreForm;
