import React, { useState } from 'react';

import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { Select, Input } from 'antd';
import theme from '@web/styles/theme';
import { CheckOutlined } from '@ant-design/icons';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import parseAddress from '@web/utils/parseAddress';
import { ErrorMessage } from 'formik';

const inputStyles = css`
  margin: 0;
  height: 43px;
  padding: 8px 16px;

  background-color: transparent;
  border: 1px solid black !important;
  border-radius: 8px;

  font-family: ${theme.fonts.AccelerateChange};
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;

  ::placeholder {
    color: #646464;
  }
`;

export const AccelerateChangeInput = styled(Input)`
  &&& {
    ${inputStyles}
  }
`;

const { Option, OptGroup } = Select;

const SelectWrapper = styled.div`
  margin: unset;
  width: 100%;
`;

const StyledSelect = styled(Select)`
  &&& {
    width: 100%;
    min-width: 240px;

    > div {
      ${inputStyles}

      min-height: 42px;
      border-color: ${theme.colors.gray};
    }

    color: ${theme.colors.black};
    font-size: 1rem;
    font-weight: 400;

    .ant-select-selection-placeholder {
      line-height: inherit;
      color: #646464;
    }

    svg {
      color: ${theme.colors.blue60};
    }

    &:hover {
      border-color: ${theme.colors.teal};

      svg {
        color: ${({ disabled }) => (disabled ? theme.colors.blue60 : theme.colors.blue)};
      }
    }
  }
`;

const OptionContent = styled.div`
  display: flex;
  align-items: center;

  height: 35px;
  max-height: 100%;

  font-family: ${theme.fonts.AccelerateChange};
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  svg {
    margin-right: 0.5rem;
  }
`;

export const AccelerateChangeSelect = ({
  value,
  options,
  groupedOptions,
  onChange,
  name,
  placeholder,
  mode,
  wrapperStyle,
  tooltip,
  ...rest
}) => {
  const [visible, setVisible] = useState(false);

  const renderOptions = (item, index) => (
    <Option key={`${index}${item.label}`} value={item.value} label={item.label}>
      <OptionContent>
        {item.icon}
        {item.label}
      </OptionContent>
    </Option>
  );

  return (
    <SelectWrapper style={wrapperStyle}>
      <StyledSelect
        name={name}
        placeholder={placeholder}
        value={mode === 'multiple' && !value ? [] : value}
        open={visible}
        showArrow
        onChange={onChange}
        onDropdownVisibleChange={setVisible}
        mode={mode}
        menuItemSelectedIcon={
          <CheckOutlined style={{ color: theme.colors.blue, transform: 'translateY(-3px)' }} />
        }
        {...rest}
      >
        {groupedOptions
          ? groupedOptions.map((item, index) => (
              <OptGroup key={index} label={item.label}>
                {item.options.map(renderOptions)}
              </OptGroup>
            ))
          : options?.map(renderOptions)}
      </StyledSelect>
    </SelectWrapper>
  );
};

const optionsProps = PropTypes.arrayOf(
  PropTypes.shape({
    icon: PropTypes.node,
    label: PropTypes.string.isRequired,
    value: PropTypes.any,
  }),
);

AccelerateChangeSelect.propTypes = {
  groupedOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      options: optionsProps,
    }),
  ),
  mode: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: optionsProps,
  placeholder: PropTypes.string,
  tooltip: PropTypes.string,

  value: PropTypes.any,
  wrapperStyle: PropTypes.object,
};

AccelerateChangeSelect.defaultProps = { placeholder: 'Select ...', wrapperStyle: null };

const StyledAutocomplete = styled(ReactGoogleAutocomplete)`
  &&& {
    ${inputStyles}
  }
`;

export const AccelerateChangeAddress = ({ onChange, ...rest }) => {
  const selectPlace = place => {
    const { city, state_abbrev } = parseAddress(place);
    onChange({ city, state: state_abbrev });
  };

  const handleValueChange = event => {
    if (event.target.value === '') {
      onChange({
        city: '',
        state_abbrev: '',
      });
    }
  };

  return (
    <StyledAutocomplete
      className="ant-input ant-input-lg"
      apiKey={process.env.REACT_APP_GOOGLE_MAPS_API}
      onChange={handleValueChange}
      onPlaceSelected={selectPlace}
      options={{
        componentRestrictions: { country: 'us' },
        types: ['(regions)'],
      }}
      {...rest}
    />
  );
};

AccelerateChangeAddress.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export const StyledErrorMessage = styled(ErrorMessage).attrs({ component: 'div' })`
  color: #ea5156;
  font-size: 12px;
  font-family: ${theme.fonts.AccelerateChange};
`;
