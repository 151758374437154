import React, { useEffect, useState, useMemo, useCallback } from 'react';
import theme, { media } from '@web/styles/theme';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Divider, message } from 'antd';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Spinner } from '@web/components/common/Loading';

import { useTranslation } from 'react-i18next';
import { getVoteScorePoint } from '@web/utils/getVoterInfo';
import ACRoutes from '@web/services/api/accelerateChange';
import { checkForElections, getLastElections } from '@web/components/AccelerateChange/utils';
import Icon from '@web/components/common/Icon';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { capitalize } from '@web/utils/string';
import useUtmParams from '@web/components/AccelerateChange/useUtmParams';

const PageWrapper = styled.div`
  color: black;

  width: 100%;
  min-height: 100vh;
  background-color: ${({ color }) => color ?? '#fff2e1'};

  display: flex;
  flex-direction: column;

  transition: background-color 0.3s ease-in-out;
`;

const InnerContainer = styled.div`
  width: 868px;
  margin: 24px auto 24px;

  ${media.nlg} {
    padding: 0 16px;
    width: 100%;
  }
`;

const Intro = styled.p`
  margin: 0;
  padding: 0;

  font-weight: 500;
  font-size: 40px;
  line-height: 55px;
`;

const SubHeader = styled.h3`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin-top: 30px;
`;

const Text = styled.p`
  margin: 0;
  padding: 0;

  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const VertFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 10px;
`;

const Title = styled.h2`
  font-weight: 700;
  font-size: 24px;
  line-height: 55px;
`;

const StyledButton = styled(Button)`
  &&& {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    height: 46px;
    width: 100%;
    border-radius: 8px;

    display: flex;
    gap: 5px;
    background-color: transparent;
    border: 1px solid black;
    color: black;

    margin-top: 16px;

    :hover {
      background-color: black;
      color: white;
    }

    :disabled {
      border: none;
      background-color: ${theme.colors.white};
      opacity: 35%;
      color: black;
    }
  }
`;

const getIcon = bool => (bool ? <CheckOutlined /> : <CloseOutlined />);

const VoterScoreInfo = () => {
  const { voterbaseId } = useParams();
  const { t } = useTranslation();
  const params = useUtmParams();

  const [isLoading, setIsLoading] = useState(false);
  const [voterData, setVoterData] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    if (voterbaseId) {
      ACRoutes.getVoter(voterbaseId, params.utm_campaign, params.utm_source, params.utm_medium)
        .then(data => {
          if (Object.keys(data.data).length === 0) throw new Error('Voter not found!');
          setVoterData(data.data);
        })
        .catch(error => message.error(error?.response?.data?.message || error?.message))
        .finally(() => setIsLoading(false));
    }
  }, [params.utm_campaign, params.utm_medium, params.utm_source, voterbaseId]);

  const electionsHistory = useMemo(
    () => (voterData ? checkForElections(voterData, true) : null),
    [voterData],
  );

  const noVotingHistory = useMemo(
    () => (electionsHistory ?? []).every(item => !item.voted),
    [electionsHistory],
  );

  const groupedElectionHistory = useMemo(() => {
    const history = electionsHistory ?? [];
    const groupedByYear = history.reduce((acc, curr) => {
      if (!acc[curr.year]) {
        acc[curr.year] = [];
      }
      acc[curr.year].push(curr);
      return acc;
    }, {});

    // Step 2: Flatten the grouped object back into an array, keeping order
    const sortedGroupedData = Object.keys(groupedByYear)
      .sort((a, b) => b - a) // Sort years in descending order
      .flatMap(year => groupedByYear[year]);

    return sortedGroupedData;
  }, [electionsHistory]);

  const lastThreeElections = useMemo(
    () => (electionsHistory ? getLastElections(electionsHistory) : null),
    [electionsHistory],
  );

  const state = useMemo(() => voterData?.['vb.tsmart_state'], [voterData]);

  const score = getVoteScorePoint(voterData);

  const getBgColor = useCallback(() => {
    if (!lastThreeElections) return null;
    return lastThreeElections.filter(el => el.voted).length > 0 ? '#b6ddfc' : '#ea5d58';
  }, [lastThreeElections]);

  const handleShare = useCallback(() => {
    const url = window.location.href;

    if (window.navigator.share) {
      navigator.share({
        name: 'My Vote Score',
        url,
      });
    } else {
      navigator?.clipboard
        .writeText(url)
        .then(() => message.success('Link copied!'))
        .catch(() => message.error('Error copying link'));
    }
  }, []);

  const name = useMemo(
    () =>
      voterData
        ? `${capitalize(voterData?.['vb.tsmart_first_name'])} ${capitalize(
            voterData['vb.tsmart_last_name'],
          )}`
        : null,
    [voterData],
  );
  const address = useMemo(
    () =>
      voterData
        ? `${capitalize(voterData?.['vb.tsmart_city'])}, ${voterData?.['vb.tsmart_state']}`
        : null,
    [voterData],
  );

  const subtitleText = useMemo(() => {
    const isRegistered = voterData?.['vb.voterbase_registration_status'] === 'Registered';

    const stateName = t(`states.${state}`);

    if (isRegistered && noVotingHistory) {
      return `You are registered to vote in ${stateName}. You have not voted in any recent elections.`;
    }

    if (isRegistered) {
      return `
       You are registered to vote in ${stateName}. The last election you voted in was ${
        lastThreeElections.length && lastThreeElections[0].year
      }.`;
    }

    if (!isRegistered) {
      return `You are not registered to vote in ${stateName}.`;
    }

    return '';
  }, [lastThreeElections, noVotingHistory, state, t, voterData]);

  return (
    <PageWrapper color={getBgColor()}>
      <Header />
      <InnerContainer>
        {voterData && (
          <>
            <Intro>
              Based on public records, your vote score is{' '}
              <strong style={{ fontSize: 46 }}>{score.toFixed(1)}</strong>.
            </Intro>
            <Divider />
            <Intro style={{ fontSize: 28, lineHeight: '32px' }}>{subtitleText}</Intro>
            <Divider />
            <Title>Recent Voting Record</Title>
            <Text>
              <strong>{name}</strong>
            </Text>
            <Text>{address}</Text>
            <VertFlex>
              {groupedElectionHistory
                .filter(e => parseInt(e.year) >= 2012)
                .map(election => (
                  <Flex>
                    <Text>
                      {election.year} {election.type} election
                    </Text>{' '}
                    {getIcon(election.voted)}
                  </Flex>
                ))}
            </VertFlex>

            <StyledButton onClick={handleShare}>
              {t('button.share')} <Icon name="Upload" />
            </StyledButton>
            <SubHeader>
              Your score, 1-5, is based on publicly-available data provided for your state since
              2012, and may be outdated or incomplete (particularly if you recently moved and your
              prior records have not yet been consolidated).
            </SubHeader>
          </>
        )}
        {isLoading && <Spinner />}
        {!voterData && !isLoading && 'Voter not found'}
      </InnerContainer>
      <Footer useGetReadyToVote />
    </PageWrapper>
  );
};

export default VoterScoreInfo;
