import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import VoterCard from './VoterCard';
import { capitalize } from '@web/utils/string';
import useUtmParams from '@web/components/AccelerateChange/useUtmParams';

const Text = styled.p`
  margin: 0;
  padding: 0;

  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SelectVoter = ({ voters, name, city }) => {
  const params = useUtmParams();
  return (
    <Flex>
      <Text>
        We found multiple people named <strong>{name}</strong> in <strong>{city}</strong>. Find
        yourself in the list below or refresh the page to search again.
      </Text>
      {voters.map(voter => (
        <VoterCard
          key={voter['voterbase_id']}
          address={`${capitalize(voter['vb.tsmart_city'])}, ${voter['vb.tsmart_state']}`}
          age={voter['vb.voterbase_age']}
          name={`${capitalize(voter['vb.tsmart_first_name'])} ${capitalize(
            voter['vb.tsmart_last_name'],
          )}`}
          selectLink={`/my_vote_score/${voter.voterbase_id}${params.formattedParams}`}
        />
      ))}
    </Flex>
  );
};

SelectVoter.propTypes = {
  city: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  voters: PropTypes.arrayOf(PropTypes.object),
};

export default SelectVoter;
