import * as React from 'react';
import { Liquid } from 'liquidjs';
import sendError from '@web/utils/sendError';

const engine = new Liquid();

const defaultValues = {
  campaign: {
    description: '{{campaign.description}}',
    join_code: '{{campaign.join_code}}',
    name: '{{campaign.name}}',
  },
  email: '{{email}}',
  first_name: '{{first_name}}',
  full_name: '{{full_name}}',
  last_name: '{{last_name}}',
  phone: '{{phone}}',
  state_abbrev: '{{state_abbrev}}',
  user: {
    email: '{{user.email}}',
    first_name: '{{user.first_name}}',
    fullname: '{{user.fullname}}',
    last_name: '{{user.last_name}}',
    phone: '{{user.phone}}',
  },
};

function useInterpolation(str, connectedContact) {
  const [interpolated, setInterpolated] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    async function interpolate() {
      try {
        const result = await engine.parseAndRender(str, {
          ...defaultValues,
          ...connectedContact,
        });
        setInterpolated(result);
        setLoading(false);
      } catch (err) {
        sendError('Error attempting to interpolate interactive script', err);
        // In case of an error, just use the initial string to prevent the odd empty string confusing customers
        setInterpolated(str);
        setLoading(false);
      }
    }
    interpolate();
  }, [connectedContact, str]);

  return { interpolated, interpolationLoading: loading };
}

export default useInterpolation;
